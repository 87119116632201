import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { pluralize, formatCurrency } from "../../utils/format"

import BedsIcon from "../../images/icons/beds.inline.svg"
import BathsIcon from "../../images/icons/baths.inline.svg"
import classes from "./property-card.module.css"

const PropertyCard = ({ property, agent }) => {
  const [imageError, setImageError] = useState(false)
  const images = useStaticQuery(graphql`
    query {
      avatarPlaceholder: file(
        relativePath: { eq: "avatar-placeholder@2x.png" }
      ) {
        childImageSharp {
          fixed(width: 45) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  if (!property || !agent) {
    return null
  }

  const agentName = `${agent.firstName} ${agent.lastName}`

  const handleImageError = () => {
    setImageError(true)
  }

  return (
    <div className={classes.mainCard}>
      <div className={classes.mainCard__address}>
        <h2>{property.address.street}</h2>
        <h3>
          {property.address.city}, {property.address.state}{" "}
          {property.address.zip}
        </h3>
        <div className={classes.mainCard__icons}>
          <span>
            <BedsIcon />
            {property.beds} {pluralize("Bed", property.beds)}
          </span>
          <span>
            <BathsIcon />
            {property.baths.total} {pluralize("Bath", property.baths.total)}
          </span>
        </div>
      </div>
      <div className={classes.mainCard__price}>
        <div>
          <h2>{formatCurrency(property.listPrice)}</h2>
          <h4>Listing Price</h4>
        </div>
      </div>
      <div className={classes.mainCard__profileContainer}>
        <div className={classes.mainCard__profile}>
          <div className={classes.mainCard__profilePicture}>
            {imageError && (
              <Img
                fixed={images.avatarPlaceholder.childImageSharp.fixed}
                fadeIn={false}
              />
            )}
            {!imageError && (
              <img
                src={agent.avatar}
                alt={agentName}
                onError={handleImageError}
              />
            )}
          </div>
          <div>
            <h3>{agentName}</h3>
            <h4>Realtor</h4>
          </div>
        </div>
        <h5>
          DRE #{agent.dreNumber} • {agent.email} • {agent.phoneNumber}
        </h5>
        <p>
          Agent Code: <mark>{agent.referralCode}</mark>
        </p>
      </div>
    </div>
  )
}

export default PropertyCard
