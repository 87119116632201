import { match } from "@reach/router/lib/utils"

export const extractPageMatchParams = (props: any): any => {
  if (props.pageContext.matchPath) {
    const result = match(props.pageContext.matchPath, props.location.pathname)
    if (result && result.params) {
      return result.params
    }
  }
  return {}
}
