import React from "react"
import useFetch from "react-fetch-hook"
import cx from "classnames"
import siteConfig from "../../utils/config"
import { formatCurrency } from "../../utils/format"

import Spinner from "../../images/puff-spinner.inline.svg"
import classes from "./financing-options.module.css"

const FinancingOptions = ({ propertyID, property }) => {
  const { data, isLoading } = useFetch(`${siteConfig.apiUrl}/loans`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      userID: "HERO",
      propertyID,
      purchasePrice: property.listPrice,
      zipCode: property.address.zip,
      loanTerm: "30 Year Fixed",
      downPayment: null,
    }),
  })

  return (
    <div className={classes.wrapper}>
      <header className={classes.headline}>
        <h1>Financing Options</h1>
        <small>Lowest down payment</small>
      </header>
      {(isLoading || !data) && (
        <div className={classes.loadingWrapper}>
          <Spinner stroke="red" />
        </div>
      )}
      <div className={classes.loanOptions}>
        {data &&
          data.loans
            .filter(loan => loan.qualifies)
            .map((option, i) => (
              <div
                className={cx(
                  classes.loanOptions__option,
                  i === 0 && classes.loanOptions__optionTop
                )}
                key={i}
              >
                <h3>{option.name}</h3>
                <div className={classes.loanOptions__terms}>
                  <div>
                    <h2>{formatCurrency(option.monthlyPayment.value)}</h2>
                    <h5>
                      Monthly
                      <br />
                      Payment
                    </h5>
                  </div>
                  <div>
                    <h2>{(option.rate * 100).toPrecision(4)}%</h2>
                    <h5>
                      Interest Rate
                      <br />({(option.apr * 100).toPrecision(4)}% APR)
                    </h5>
                  </div>
                  <div>
                    <h2>
                      {(option.minDownPaymentPercent * 100).toPrecision(2)}%
                    </h2>
                    <h5>
                      Down Payment
                      <br />({formatCurrency(option.minDownPayment.value)})
                    </h5>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  )
}

export default FinancingOptions
