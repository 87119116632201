import React from "react"
import useFetch from "react-fetch-hook"
import Carousel from "nuka-carousel"
import * as clipboard from "clipboard-polyfill/text"
import SEO from "../components/seo"
import FinancingOptions from "../components/share/financing-options"
import PropertyCard from "../components/share/property-card"
import HeroContainer, { HeroContent } from "../components/containers/hero"
import {
  SliderNext,
  SliderPrev,
  SliderCount,
} from "../components/agents/slider-nav"
import { extractPageMatchParams } from "../utils/path"
import { formatDate } from "../utils/format"
import siteConfig, { getBuyerAppUrl } from "../utils/config"
import Spinner from "../images/puff-spinner.inline.svg"
import appstoreLogo from "../images/appstore-download.svg"
import googlePlayLogo from "../images/play-download.svg"
import equalHousingLogo from "../images/equal-housing-opportunity.svg"
import propertyPlaceholderImage from "../images/property-placeholder@2x.png"
import classes from "../components/share/share.module.css"

const EmptyState = () => (
  <>
    <SEO title="Instant. Convenient. Human." />
    <HeroContainer>
      <HeroContent>
        <Spinner stroke="red" />
      </HeroContent>
    </HeroContainer>
  </>
)

const SharePage = (props: any) => {
  const { agentID, propertyID } = extractPageMatchParams(props)

  if (!agentID || !propertyID) {
    return <EmptyState />
  }

  const propertyReq = useFetch(`${siteConfig.apiUrl}/properties/${propertyID}`)
  const agentReq = useFetch(`${siteConfig.apiUrl}/agents/${agentID}`)

  if (propertyReq.isLoading || agentReq.isLoading) {
    return <EmptyState />
  }

  const property: any = propertyReq.data
  const agent: any = agentReq.data

  if (propertyReq.error || agentReq.error || !property || !agent) {
    return <EmptyState />
  }

  const propertyHasImages =
    Array.isArray(property.images) && property.images.length > 0
  const propertyImages = propertyHasImages
    ? property.images
    : [propertyPlaceholderImage]

  return (
    <>
      <SEO
        title={property.address.street}
        image={propertyHasImages ? property.images[0] : null}
      />
      <section className={classes.heroContainer}>
        <Carousel
          renderCenterRightControls={props =>
            propertyImages.length > 1 && (
              <SliderNext className={classes.slider__next} {...props} />
            )
          }
          renderCenterLeftControls={props =>
            propertyImages.length > 1 && (
              <SliderPrev className={classes.slider__prev} {...props} />
            )
          }
          renderBottomCenterControls={null}
          renderBottomLeftControls={props => <SliderCount {...props} />}
        >
          {propertyImages.map((image: string, i: number) => (
            <div className={classes.slide} key={i}>
              <img src={image} />
            </div>
          ))}
        </Carousel>
      </section>
      <section className={classes.loansWrapper}>
        <div className="container">
          <div>
            <PropertyCard property={property} agent={agent} />
            {property && (
              <FinancingOptions property={property} propertyID={propertyID} />
            )}
            <p className={classes.guarantee}>
              RedDoor offers FHA, Conventional and Jumbo loan options. To unlock
              all loan options, download the app (agent code:{" "}
              {agent.referralCode}).
            </p>
            <div className={classes.downloadButton}>
              <a
                style={{ cursor: "pointer" }}
                onMouseDown={async e => {
                  const { button } = e

                  if (!agent.deepLink) {
                    return
                  }

                  await clipboard.writeText(agent.deepLink)

                  if (button === 0) {
                    window.open(agent.deepLink || getBuyerAppUrl("ios"))
                  }
                  if (button === 1) {
                    const newTab = window.open(
                      agent.deepLink || getBuyerAppUrl("ios"),
                      "_blank"
                    )
                    newTab && newTab.focus()
                  }
                }}
              >
                <img
                  src={appstoreLogo}
                  alt="Download on the App Store"
                  className="ios_download_img"
                />
              </a>
              <a
                style={{ cursor: "pointer" }}
                onMouseDown={async e => {
                  const { button } = e

                  if (!agent.deepLink) {
                    return
                  }

                  await clipboard.writeText(agent.deepLink)

                  if (button === 0) {
                    window.open(agent.deepLink || getBuyerAppUrl("android"))
                  }
                  if (button === 1) {
                    const newTab = window.open(
                      agent.deepLink || getBuyerAppUrl("android"),
                      "_blank"
                    )
                    newTab && newTab.focus()
                  }
                }}
              >
                <img
                  src={googlePlayLogo}
                  alt="Get it on Google Play"
                  className="ios_download_img"
                />
              </a>
            </div>
            {property && property.listingAgent && (
              <div className={classes.listingInfo}>
                <p className="text_muted">
                  Property listed by:
                  <br />
                  <strong>{property.listingAgent.name}</strong>
                  {property.listingOffice && (
                    <>
                      <br />
                      {property.listingOffice.name}
                    </>
                  )}
                </p>
                <img src={equalHousingLogo} alt="Equal Opportunity Housing" />
              </div>
            )}
            <p className={classes.disclaimer}>
              This is not a commitment to lend. Approval will be issued after a
              satisfactory review of all relevant components of approval based
              on the applicable guidelines; not all applicants are eligible.
              Rates as of {formatDate(new Date().toUTCString())}, subject to
              change until locked. Offer not available in all areas. Other
              limitations may apply. RedDoor powered by MortgageHub Inc., 1161
              Mission Street #401 San Francisco, California, 94103. DRE 02084298
              | NMLS 1838659.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default SharePage
